import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>

              <div className="watsApp">
                    <Link href='https://wa.me/+2347032617660' className="btn btn-icon2" role="button">
                        <i className="fab fa-whatsapp"></i>
                    </Link>
                    <span> Contact us now</span>
                </div>

                <footer className="page-footer font-small unique-color-dark mt-4">

                  <div className="py-4" id="consult">
                    <div className="container text-center">
                            <div className="row">
                                <div className="col-md-1"></div>
                                <div className="col-md-5">
                                    <h3 className="downloadText">Download Our Profile</h3>                
                                </div>
                                <div className="col-md-5">
                                  <Link href="./images/Revaloneq-Profile.pdf" download="">
                                    <button href="" type="button" className="btn btn-primary btn-bottom btn-md waves-effect waves-light">Download<i className="fa fa-paper-plane-o ml-1"></i></button>
                                      </Link>                
                                </div>
                                <div className="col-md-1"></div>
                            </div>
                    </div>
                  </div>

                  
                  <div className="container seconeFooter text-md-left mt-5">

                    
                    <div className="row mt-3">

                      
                      <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

                        <h6 className="text-uppercase font-weight-bold">REVALONEQ NIG LTD</h6>
                        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
                        <p>20 Rumuola Road, Luli Mall by Heritage Bank Rumuola, Port Harcourt, Rivers state.</p>

                      </div>
                      
                      <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                        <h6 className="text-uppercase font-weight-bold">Services</h6>
                        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
                        <p>
                          <Link href="/Our-Services">Construction</Link>
                        </p>
                        <p>
                          <Link href="/Our-Services">Dredging</Link>
                        </p>
                        <p>
                          <Link href="/Our-Services">Mechanical and Marine Logistic</Link>
                        </p>
                      </div>
                      

                      
                      <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                        <h6 className="text-uppercase font-weight-bold">Company</h6>
                        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
                        <p>
                          <Link href="/">Home</Link>
                        </p>
                        <p>
                          <Link href="/About-Us">About Us</Link>
                        </p>
                        <p>
                          <Link className="waves-effect waves-light"  href="/Contact-us">Contact Us</Link>
                        </p>

                      </div>
                      

                      
                      <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                        
                        <h6 className="text-uppercase font-weight-bold">Info</h6>
                        <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
                        <p>
                          <Link href="https://www.instagram.com/revaloneq/">
                            <i className="fab fa-instagram mr-3"></i> Follow us on Instagram</Link>
                          </p> 
                        <p>
                          <i className="fa fa-envelope mr-3"></i> info@reveloneq.com</p>
                        <p>
                          <i className="fa fa-phone mr-3"></i>  +234 (0) 803 798 6697</p>

                      </div>
                      

                    </div>
                    

                  </div>
                  
                  <div className="footer-copyright text-center py-3">© 2021 Copyright:
                    <Link href=""> REVALONEQ NIG LTD</Link>
                  </div>
                  

                </footer>
                            
        </>
    );
};

export default Footer;