import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Home from './Pages/Home';
import Aboutus from './Pages/Aboutus';
import Services from './Pages/Services';
import Contact from './Pages/Contactus';
import './App.css';

function App() {
  return (

    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Home />} exact/>
          <Route path="/About-us" element={<Aboutus />} exact/>
          <Route path="/Our-Services" element={<Services />} exact/>
          <Route path="/Contact-us" element={<Contact />} exact/>
        </Routes>
    </BrowserRouter>
    
  );
}

export default App;
