import React from 'react';
import styles from  './css/home.module.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const Services = () => {
	// const owlResponsive = {
	// 	0: {
	// 		items: 1,
	// 	},
	// 	450: {
	// 		items: 2,
	// 	},
	// 	600: {
	// 		items: 2,
	// 	},
	// 	1000: {
	// 		items: 3,
	// 	}
	// }
    return (
        <>

        <Nav/>



        <section className={styles.boxAbtOne}>
                <div className="row">
                        <div className="col-lg-8">
                            <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="0.5s">Our Services</h4>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1s">We follow through procedures to ensure that clients objectives are top priority in the planning and execution of all our processes.</p>
                        </div>
                      </div>



                    
                <div className="row">
                    <div className="col-lg-6">
                            <img src="./images/serv1.png" className="d-block w-100 img-responsive img-fluid serv1Img wow fadeInUp"  data-wow-duration="1s" alt=""/>
                    </div>
                    <div className="col-lg-6">
                            <div className="row">
                                <div className="col-12" id='hide-sm'>
                                        <div className="row">
                                            <div className="col-9">
                                                  <img src="./images/serv3.png" className="d-block w-100 img-responsive img-fluid wow fadeInUp"  data-wow-duration="1.2s" alt=""/>   
                                                </div>
                                            <div className="col-3"></div>
                                            </div>
                                    </div>
                                <div className="col-12">
                                       <img src="./images/serv2.png" className="d-block img-responsive img-fluid serv2Img wow fadeInUp"  data-wow-duration="1.5s" alt=""/>
                                    </div>

                             </div>
                        
                    </div>

                            

                  </div>

          </section>



          <section className="servContain">

          <div className="img-slider">
            
            <div className="row">
                <div className="col-lg-7">
                    <div className="servp" id='Mechanical'>
                        <div className="line-header"></div>
                            <h2 className="mg-bottom wow fadeInUp ourPolicies"  data-wow-duration="1s">Mechanical and Marine Logistics</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.5s">REVALONEQ NIG LTD renders integrated service for sea vessels management, technical maintenance equipment, as well as professional assistance in issues relating to commercial operation of a vessel.</p>
                    </div>
                </div>
                <div className="col-lg-12">

	            	{/* <OwlCarousel items={3} responsive={owlResponsive} margin={8} autoplay={false}  loop={false} nav={true} dots={false}>
                                    <div >
										<img className="img wow fadeInRight"  data-wow-duration="1s" src="./images/Projects/marine4.jpeg"  id="myImg"/>
                                      </div> 
                                    <div >
										<img className="img wow fadeInRight"  data-wow-duration="1.2s" src="./images/Projects/marine.JPG"  id="myImg"/>
                                      </div>  
                                    <div >
										<img className="img wow fadeInRight"  data-wow-duration="1.5s" src="./images/Projects/marine3.JPG"  id="myImg"/>
                                      </div>   
                              </OwlCarousel>  */}


                </div>
            </div>

          </div>


          <div className="img-slider">

            <div className="row">
                <div className="col-lg-7">
                    <div className="servp">
                        <div className="line-header"></div>
                            <h2 className="mg-bottom wow fadeInUp ourPolicies"  data-wow-duration="1s">Construction of Jetties/canals</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.5s">A jetty is a structure that projects from land out into water. A jetty may serve as a breakwater, as a walkway, or both; or, in pairs, as a means of constricting a channel, REVALONEQ NIG LTD, uses quality equipment to provide this service.</p>
                    </div>

                </div>
                <div className="col-lg-12">
{/* 
                        <OwlCarousel items={3} responsive={owlResponsive} margin={8} autoplay={false}  loop={false} nav={true} dots={false}>
                                        <div >
                                            <img className="img wow fadeInRight"  data-wow-duration="1s" src="./images/Projects/jetty1.JPG"  id="myImg"/>
                                        </div>  
                                        <div >
                                            <img className="img wow fadeInRight"  data-wow-duration="1.5s" src="./images/Projects/jetty2.JPG"  id="myImg"/>
                                        </div>  
                                        <div >
                                            <img className="img wow fadeInRight"  data-wow-duration="1.5s" src="./images/Projects/jetty3.JPG"  id="myImg"/>
                                        </div>   
                                </OwlCarousel>  */}
                    

                </div>
            </div>

            </div>


            <div className="img-slider">

            <div className="row">
                <div className="col-lg-7">
                    <div className="servp">
                        <div className="line-header"></div>
                            <h2 className="mg-bottom wow fadeInUp ourPolicies"  data-wow-duration="1s">Dredging</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.5s">One of the integral part of REVELONEQ NIG LTD. is our commitment to development. We provide our client with professional dredging services and the necessary equipments for the land reclamation and stock pilling.</p>
                    </div>

                </div>
                <div className="col-lg-12">

                    {/* <OwlCarousel items={3} responsive={owlResponsive} margin={8} autoplay={false}  loop={false} nav={true} dots={false}> 
                                    <div >
                                        <img className="img wow fadeInRight"  data-wow-duration="1s" src="./images/Projects/dredging3.JPG"  id="myImg"/>
                                    </div>  
                                    <div >
                                        <img className="img wow fadeInRight"  data-wow-duration="1.2s" src="./images/Projects/dredging1.JPG"  id="myImg"/>
                                    </div>  
                                    <div >
                                        <img className="img wow fadeInRight"  data-wow-duration="1.5s" src="./images/Projects/dredging2.JPG"  id="myImg"/>
                                    </div>  
                            </OwlCarousel>  */}
                  </div>
              </div>

              </div>

            

          </section>
    






        <Footer/>


            
        </>
    );
};

export default Services;