import React, {useState} from 'react';
import styles from  './css/home.module.css';
import Nav from './components/Nav';
import Footer from './components/Footer';
import { useForm } from "react-hook-form";

const Contactus = () => {

    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [content, setContent] = useState('');
    const [btnisloading, setBtnIsLoading] = useState(false);

    const onSubmit = (data) => {

    setBtnIsLoading(true);

    return fetch(`https://api.enterodocuments.com/api/developer/sendMail`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
            name:data.name,
            email: data.email,
            message: data.message
        })
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
        //   console.log(responseJson)
          if(responseJson.status === "success"){
            alert("Message sent successfully");
            setBtnIsLoading(false);
          }
          if(responseJson.status === "error"){
            alert("Please try again");
            setBtnIsLoading(false);
          }

        })
        .catch((error) => {
        console.error(error);
        })
        
    };


    return (
        <>
            <Nav/>

            <section className={styles.boxAbtOne}>
                            <div className="row text-center">
                                    <div className="col-lg-12">
                                        <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="0.5s">Contact Us</h4>
                                        <p className="paragraph wow fadeInUp"  data-wow-duration="1s"></p>
                                    </div>
                                </div>

                                
                            <div className="row">

                                <div className="col-lg-3"></div>
                                <div className="col-lg-6">
                                        <div className="container py-4">
                                     <form  onSubmit={handleSubmit(onSubmit)} id="contactForm" >


                                                <div className="mb-3">
                                                    <label className="form-label" for="name">Name</label>
                                                    <input className="form-control" id="name" type="text" placeholder="Full Name" name='name'
                                                    {...register("name", { required: true })}/>
                                                    {errors.name && <span>Full Name is required</span>}
                                                    
                                                </div>


                                                <div className="mb-3">
                                                    <label className="form-label" for="emailAddress">Email Address</label>
                                                    <input className="form-control" id="emailAddress" type="email" placeholder="Email Address" name="email"
                                                    {...register("email", { required: true })}/>
                                                    {errors.email && <span>Email is required</span>}
                                                    
                                                </div>


                                                <div className="mb-3">
                                                    <label className="form-label" for="message">Message</label>
                                                    <textarea className="form-control" id="message" type="text" placeholder="Message" name='message' {...register("message", { required: true })}/>
                                                    {errors.message && <span>Message is required</span>}
                                                </div>


                                                <div className="d-none" id="submitSuccessMessage">
                                                    <div className="text-center mb-3">Form submission successful!</div>
                                                </div>


                                                <div className="d-none" id="submitErrorMessage">
                                                    <div className="text-center text-danger mb-3">Error sending message!</div>
                                                </div>


                                                <div className="d-grid">
                                                    <button className="btn btn-primary btn-lg" id="submitButton" type="submit">
                                                    {btnisloading ? (<> Please Wait.. </>) : ( <> Send</>)}
                                                    </button>
                                                </div>

                                                </form>
                                          </div>
                                  </div>
                                <div className="col-lg-3"></div>

                            </div>

                    </section>


           <Footer/>
                        
        </>
    );
};

export default Contactus;