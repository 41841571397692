import React from 'react';
import styles from  './css/home.module.css';
import Nav from './components/Nav';
import Footer from './components/Footer'
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";


const Home = () => {
	// const owlResponsive = {
	// 	0: {
	// 		items: 1,
	// 	},
	// 	450: {
	// 		items: 2,
	// 	},
	// 	600: {
	// 		items: 2,
	// 	},
	// 	1000: {
	// 		items: 3,
	// 	}
	// }



    return (
        <>


        <Nav/>

  

            <section className={styles.sectionOne}>
                <div className="row wow fadeIn"  data-wow-duration="1s" style={{backgroundColor:"#F4FCFE"}}>
                        <div className="col-lg-6 boxOne">
                            <div className="slide">
                                <h4 className="slideTitle wow fadeInUp"  data-wow-duration="1.2s"> We deliver first class services.</h4>
                                <p className="paragraph wow fadeInUp"  data-wow-duration="1.5s">REVALONEQ NIGERIA LIMITED is an indigenous service company engaged in the design, maintenance and construction of infrastructure, with experience spread across various areas of civil and mechanical works.</p>

                                <Link to="/About-us" className="btn btn-primary btn-rounded wow fadeInUp"  data-wow-duration="1.8s" type="button">About us</Link>

                            </div>
                        </div>
                        <div className="col-lg-6 boxTwo">
                            <img src="./images/DJI_0458.JPG" className="d-block w-100 sliderImg" alt="dregding"/>
                        </div>
                     </div>
               </section>


               <section className='contain'>
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="infoTop text-center wow fadeInUp"  data-wow-duration="1.2s">Our experience is spread across<br/> these key services.</h2>
                    </div>
                    <div className="col-lg-4"></div>
                    <div className="col-12 col12">
                        <div className="row">

                        <div className="col-lg-4">
                            <img src="./images/icons/civil.png" className="img-fluid d-block serv-icon wow fadeInUp"  data-wow-duration="1s" alt=""/>
                            <h6 className="serv-title mt-2 wow fadeInUp"  data-wow-duration="1.2s">Construction of Jetties/canals</h6>
                            <p className="serv-p wow fadeInUp"  data-wow-duration="1.5s"> A jetty may serve as a breakwater, as a walkway, or both; or, in pairs, as a means of constricting a channel.</p>
                          </div>

                        <div className="col-lg-4">
                            <img src="./images/icons/dredging.png" className="img-fluid d-block serv-icon wow fadeInUp"  data-wow-duration="1s" alt=""/>
                            <h6 className="serv-title mt-2 wow fadeInUp"  data-wow-duration="1.2s">Dredging</h6>
                            <p className="serv-p wow fadeInUp"  data-wow-duration="1.5s">We provide our client with professional dredging services and the necessary equipments for the land reclamation and stock pilling.</p>
                        </div>
                        <div className="col-lg-4">
                            <img src="./images/icons/logistics.png" className="img-fluid d-block serv-icon wow fadeInUp"  data-wow-duration="1s" alt=""/>
                            <h6 className="serv-title mt-2 wow fadeInUp"  data-wow-duration="1.2s">Mechanical and Marine Logistic</h6>
                            <p className="serv-p wow fadeInUp"  data-wow-duration="1.5s">We render integrated service for sea vessels management, technical maintenance <br/>equipment.</p>
                        </div>

                        </div>

                    </div>

                </div>
               </section>


                     
         
               <div className="sectionThree">
                 <div className="row">
                  <div className="col-lg-3">
                    <div className="pt-5">
                        <div className="line-header"></div>
                      <h2 className="mg-bottom-24px wow fadeInUp ourPolicies"  data-wow-duration="1s">Our Policies</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1s">Our Policies are an essential part of our organization. it helps provide a roadmap for day-to-day operations.</p> 
                    </div>
                  </div>
                  <div className="col-lg-9">

                      <div class="tainer">

                        <div class="slider">

                            <div class="item">
                                <img src="./images/policy1.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1s" alt=""/>
                            </div>
                            
                            <div class="item">
                                <img src="./images/policy5.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.2s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy2.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.5s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy3.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.8s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy4.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="2s" alt=""/>
                            </div>


                        </div>


                        </div>
                                    
                  </div>

                 </div>
                </div>




             <Footer/>



            
        </>
    );
};

export default Home;