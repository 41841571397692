import React from 'react';
import styles from  './css/home.module.css';
import Nav from './components/Nav';
import Footer from './components/Footer'

const Aboutus = () => {
    return (
        <>

        <Nav/>



        <section className={styles.boxAbtOne}>
                <div className="row">
                        <div className="col-lg-6">
                        <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s">About Us</h4>
                            {/* <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s">The Story behind<br />our company</h4> */}
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">REVALONEQ NIGERIA LIMITED is a company located in the heart of Port Harcourt rivers state Nigeria, with a high rising profile in the engineering and marine industry and is rapidly growing and improving with a work force that is highly skilled and innovative.</p>
                        </div>
                        </div>



                <img src="./images/Asset17.png" className="d-block w-100 img-responsive img-fluid abtImg  wow fadeInUp"  data-wow-duration="1.5s" alt=""/>
                    
                <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                           <div className="line-header center"></div>
                           <h2 className="h4 text-center  wow fadeInUp"  data-wow-duration="1s">We are an indigenous service company engaged in the design, maintenance and construction of infrastructure, with experience spread across various areas of civil and mechanical works.</h2>
                        </div>
                        <div className="col-lg-2"></div>

                  </div>

          </section>
          

          <section className="abtContain">
              <div className="row">
                <div className="col-lg-6">

                    <div>
                        <div className="line-header wow fadeInUp"  data-wow-duration="1s"></div>
                            <h2 className="mg-bottom-24px wow fadeInUp ourPolicies"  data-wow-duration="1s">Our mission</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">
                            REVALONEQ NIGERIA LIMITED is a company located in the heart of Port Harcourt rivers state Nigeria, with a high rising profile in the engineering and marine industry and is rapidly growing and improving with a work force that is highly skilled and innovative</p>
                    </div>
                    
                </div>
                <div className="col-lg-6">
                         <div>
                            <div className="line-header wow fadeInUp"  data-wow-duration="1s"></div>
                            <h2 className="mg-bottom-24px wow fadeInUp ourPolicies"  data-wow-duration="1s">Our vision</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">To become and remain the prime and foremost leading and respected servicing company in Nigeria in the Maritime, Civil Engineering and Oil &amp; Gas sector. An organization that provides relevant products and services cost effective in an accurate, timely and professional manner poised towards a concept of excellent technical capabilities and total service delivery.</p>
                            </div>
                    
                </div>

              </div>

          </section>


          <section className="objContain">
              <div className="div">
                    <div className="row">
                            <div className="col-lg-6">

                                    <div className="line-header-2"></div>
                                        <h2 className="mg-bottom-24px wow fadeInUp"  data-wow-duration="1s">Our objective is to provide our clients with an “I am assured” experience.</h2>
                                
                            </div>
                            <div className="col-lg-6">
                                    <div className="">
                                        <p className="paragraph wow fadeInUp"  data-wow-duration="1s">
                                        Our emphasis on clear communication and follow through procedures ensures that client’s objectives are top priority in the planning and execution of all our processes.</p>
                                        <p className="paragraph wow fadeInUp"  data-wow-duration="1s">We aim to provide low cost but effective services to various high net-worth corporate concerns.</p>
                                        </div>
                                
                            </div>
                    </div>
              </div>   

          </section>



          <div className="sectionThree">
                 <div className="row">
                  <div className="col-lg-3">
                    <div className="pt-5">
                        <div className="line-header"></div>
                      <h2 className="mg-bottom-24px wow fadeInUp ourPolicies"  data-wow-duration="1s">Our Policies</h2>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1s">Our Policies are an essential part of our organization. it helps provide a roadmap for day-to-day operations.</p> 
                    </div>
                  </div>
                  <div className="col-lg-9">

                      <div class="tainer">

                        <div class="slider">

                            <div class="item">
                                <img src="./images/policy1.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1s" alt=""/>
                            </div>
                            
                            <div class="item">
                                <img src="./images/policy5.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.2s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy2.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.5s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy3.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="1.8s" alt=""/>
                            </div>

                            <div class="item">
                                <img src="./images/policy4.png" className="d-block w-100 policyImg wow fadeInRight"  data-wow-duration="2s" alt=""/>
                            </div>


                        </div>


                        </div>
                                    
                  </div>

                 </div>
                </div>








        <Footer/>


            
        </>
    );
};

export default Aboutus;