import React, {useEffect} from 'react';
import WOW from 'wowjs';
import { Link } from "react-router-dom";



const Nav = () => {



  useEffect(() => {
    new WOW.WOW({
    live: false
        }).init();
    },[]);



    return (
        <>



        	
				<div className="contain-nav">


                <nav className="navbar navbar-expand-lg navbar-light wow fadeInDown"  data-wow-duration="1s" id="navbarNav1">
                        <div className="container">
                            
                        <a href="./"> 
                            <a className="navbar-brand" id="logo-m"> 
                                <img src="images/logo.jpeg" className="d-inline-block align-top navLogo" alt=""/>     
                            </a>
                        </a>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                <ul className="navbar-nav  mx-auto nm-r">
                                    <li className="nav-item">
                                    <Link to="/"><a className="nav-link waves-effect waves-light active-nav text-uppercase">Home</a></Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link to="/About-us"><a className="nav-link waves-effect waves-light text-uppercase">About Us</a></Link>
                                    </li>
                                    {/* <li className="nav-item">
                                    <a href="/construction"><a className="nav-link waves-effect waves-light">Our Policies</a></a>
                                    </li> */}
                                    <li className="nav-item">
                                    <Link to="/Our-Services"><a className="nav-link waves-effect waves-light text-uppercase">Our Services</a></Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link to="/Contact-us"><a className="nav-link waves-effect waves-light text-uppercase">Contact Us</a></Link>
                                    </li>
                                </ul>
                                <ul className="navbar-nav  ms-auto nm-r">
                                    <li className="nav-item">
                                        <Link  to="./images/Revaloneq-Profile.pdf" className="nav-link  scroll-link waves-effect waves-light ">Download Company Profile</Link>
                                    </li>
                                    </ul>
                            </div>
                        </div>
                    </nav>
                    {/* <div className="bottom-nav"></div> */}

              </div>


        </>
    );
};

export default Nav;